import React from "react";
import RickleFooter from "../rickle/RickleFooter";
import RickleForPeople from "./RickleForPeople";
import { Grid, Typography, Paper } from "@mui/material";
export default function RicklePrivacyPolicyPage() {
  return (
    <React.Fragment>
      <RickleForPeople />
      <Grid container spacing={2} width={"100%"}>
        <Paper
          elevation={0}
          sx={{ m: 5, px: 2.2, border: "7px solid #C121A4", width: "100%" }}
        >
          <Grid
            item
            container
            gap={2}
            xs={12}
            alignItems="left"
            justifyContent="left"
            align="left"
            overflow={"hidden"}
          >
            <Grid item xs={12}>
              <Typography variant="h3" color="red">
                Privacy Policy
              </Typography>
              <Typography variant="h5" color="red">
                Last updated: January 1st, 2023
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1">
                We operate a website and related platform features (“Site”). We
                may refer to RICKLE as “we”, “us”, or “our”. We may refer to you
                as “you”, or “your”. The purpose of this policy is to inform you
                about the personal information we collect, how we use and share
                that information, and the ways in which you can control how we
                use and share that information. By using our Site, you agree to
                the terms of this policy and you expressly consent to the
                collection, use, and disclosure of your personal information in
                accordance with this policy.
              </Typography>
              <Typography variant="h5">
                A. Information Collected and Received from You
              </Typography>
              <Typography variant="h6">1. User Registration Data</Typography>
              <Typography variant="body1">
                At the time of registration on the Site, we may ask you to
                provide certain information, which includes your email address
                and name (the “User Registration Data”).
              </Typography>
              <Typography variant="h6">
                2. Information You May Provide
              </Typography>
              <Typography variant="body1">
                You may elect to provide a variety of information during your
                interactions with us and the Site, such as emails you may send
                us, SMS or text messages that you may sign up for and emails or
                newsletters that you sign up to receive from us. We, or third
                parties acting on our behalf, receive data from you whenever you
                provide us with information.
              </Typography>
              <Typography variant="h6">3. Account Information</Typography>
              <Typography variant="body1">
                When you register or sign up on the Site for certain features,
                products, services, newsletters or special offers, you may
                provide us, and we may collect, your contact information,
                including your name and email address (“Account Information”).
              </Typography>
              <Typography variant="h6">
                4. Information as to your Device
              </Typography>
              <Typography variant="body1">
                When you access and use the Site from a computer, mobile phone,
                tablet or other electronic device, we may collect information
                about those devices, including your browser type and Internet
                Protocol (IP) address. If you access the Site from a mobile
                phone, tablet, automobile, or other electronic device, we may
                collect and store information such as device type, operating
                system version and type, unique identifiers (such as mobile
                advertising ID), wireless carrier, and other related information
                for that device.
              </Typography>
              <Typography variant="h6">
                5. Information as to your Location
              </Typography>
              <Typography variant="body1">
                When you sign up on the Site, you may provide us with your zip
                or postal code. We may also collect location information from
                devices you use while accessing the Site. Depending on the
                device and operating system, device-based location information
                may be derived from GPS and nearby wireless signals such as cell
                towers, Wi-Fi networks, and Bluetooth beacons. We will not
                collect your device-based location information unless you have
                permitted us to access location services on your device. You can
                disable this access at any time in your system settings.
              </Typography>
              <Typography variant="h6">6. Other Information</Typography>
              <Typography variant="body1">
                We may collect certain information through the use of log files
                and servers. These servers create log files as part of the
                process of setup and configuration. Information in a log file
                may include IP address, browser type, Internet service provider,
                date/time stamps, and other usage information and statistical
                information.
              </Typography>

              <Typography variant="h5">
                B. Information Collected and Received from Others
              </Typography>
              <Typography variant="body1">
                We may receive or collect information about you from third
                parties or others. It may be collected and stored on our servers
                with other information we may have already received or collected
                from you. These third parties include:
                <ul>
                  <li>
                    Service providers that provide or make available
                    advertising, features and functionality, and content on or
                    through the Site.
                  </li>
                  <li>
                    Advertisers, marketing companies, advertising agencies that
                    collect and maintain information on their customers. These
                    third parties may provide us with certain information about
                    those customers for the purposes of serving advertisements
                    and/or marketing offers to their customers on the Site.
                  </li>
                </ul>
              </Typography>
              <Typography variant="body1">
                We are not responsible for, and will assume no liability, if a
                business partner or other entity collects, uses, or shares any
                information about you in violation of its own privacy policy or
                any applicable laws, rules, or agreements.
              </Typography>
              <Typography variant="h5">
                C. How We Use The Information
              </Typography>
              <Typography variant="body1">
                We may use your User Registration Data and other information or
                data we receive or collect, as well as data we derive or infer
                from combinations of the foregoing, for a variety of purposes,
                such as:
                <ul>
                  <li>
                    To facilitate the creation of and secure your account
                    subscription on the Site.
                  </li>
                  <li>
                    To provide and improve the Site and to develop new products
                    and services.
                  </li>
                  <li>
                    To customize and personalize the advertising and other
                    content we deliver to you both on the Site and on other
                    services offered by our publishing partners. We use this
                    information to provide you with relevant and interesting
                    advertising and other content.
                  </li>
                  <li>
                    To fulfill your requests for certain products and services,
                    such as distributing electronic newsletters and enabling you
                    to participate in surveys and public forums.
                  </li>
                  <li>
                    To send you information that you requested or agreed to
                    receive.
                  </li>
                  <li>
                    To alert you to the latest developments and features on our
                    Site and to notify you of administrative information, such
                    as security or support and maintenance advisories.
                  </li>
                  <li>
                    To invite you to participate in events or special promotions
                    related to products we think you may like or in which you
                    may be interested.
                  </li>
                </ul>
              </Typography>
              <Typography variant="body1">
                We do not sell or give your email address to other companies for
                their own marketing purposes without your permission. However,
                we may use your email address or other User Registration Data to
                provide you with technical support, send you notices about the
                Site or other promotional offers you have elected to receive,
                and to serve you with ads that are more relevant to your
                interests if you so choose. We may also work with data partners
                and advertising platforms to help increase the relevance of ads
                we provide to you. In doing so, we may use information
                representing an encrypted or hashed value derived from
                information we have received, such as your email address, in
                connection with these partners and platforms.
              </Typography>
              <Typography variant="body1">
                We do not sell or give your Contact Information to companies for
                their own marketing purposes without your permission. We do use
                Contact Information, however, to contact you, and to provide you
                with special offers and other information. If you provided your
                Contact Information as part of your use of the Site or your
                account, we may use your Contact Information to update you on
                new features and products. You may contact us at any time to
                request that we cease using your Contact Information.
              </Typography>
              <Typography variant="h5">
                D. Information Collection Technologies/Cookies
              </Typography>
              <Typography variant="h6">
                We use cookies and the identifier in these cookies to help us
                manage and report on your interaction with the Site. Through
                cookies, we are able to collect information that we use to
                improve the Site, keep count of return visits to our website or
                our advertisers’ or partners’ websites, collect and report on
                aggregate statistical information, authenticate your login
                credentials, or manage multiple instances of the Service in a
                single browser. We may also collect other data such as the page
                or site that referred you to the Site, the date and time you
                visited the Site, and your current IP address. You may be able
                to remove cookies by following directions provided in your
                browser.
              </Typography>

              <Typography variant="h6">
                Advertisers and other third-party advertising entities that
                advertise on the Site may place or recognize a unique cookie on
                your hard drive. These entities may use these cookies to collect
                information about you, which may include how many times you have
                seen their ads or whether you have interacted with an ad. Be
                sure to check with your web browsers on how to accept or reject
                third-party cookies.
              </Typography>

              <Typography variant="h5">
                E. Sharing Information with Others
              </Typography>
              <Typography variant="body1">
                We may share information we collect with our subsidiaries,
                successors or related companies for the purposes described in
                our respective privacy policies, and to offer, provide, and
                improve services and products offered both individually and
                jointly with these companies.
              </Typography>

              <Typography variant="body1">
                We may share information we receive or collect, such as when you
                give us permission through an affirmative election (for example,
                clicking “yes” in response to a message or email inquiring as to
                whether to “share my email with this advertiser”). We may also
                share information if we partner with a third party to provide
                services on our behalf, such as credit card processors, customer
                support, advertising services, or bulk email processors who may
                send out emails on our behalf. These companies are authorized to
                use your personal information only as necessary to provide these
                services to us. They are not authorized to use your personal
                information for their own, unrelated purposes.
              </Typography>

              <Typography variant="body1">
                We may also share your information in order to comply with legal
                requests such as subpoenas or court orders or to protect or
                defend our legal rights or property or the legal rights of third
                parties, employees, agents, and contractors (including under our
                agreements).
              </Typography>

              <Typography variant="body1">
                Additionally, we may share information we gather from devices
                you use to access the Site with its third-party vendors,
                manufacturers, service providers or advertising entities. We
                share this information for a variety of purposes such as
                tracking advertising, estimating the number of users, security
                and fraud detection, debugging problems and for providing you
                with more relevant advertisements on the Site.
              </Typography>

              <Typography variant="body1">
                If there is a transfer or sharing of your personal information
                in accordance with this policy, we will seek assurances from the
                recipients of such information (prior to the transfer) that they
                will safeguard the information in a manner consistent with this
                policy.
              </Typography>

              <Typography variant="h5">
                F. Account Management and Settings
              </Typography>
              <Typography variant="body1">
                You will have the ability to access and modify your User
                Registration Data on your account, which you may do through the
                Settings section of our Site at any time.
              </Typography>

              <Typography variant="body1">
                You have control through Settings on your Account to limit data
                we collect from you and how we use it. You can also download the
                information you have shared with the Site.
              </Typography>

              <Typography variant="body1">
                Your User Registration Data will be retained by us while your
                account is active. We will retain and use this information for
                the purposes for which it was collected (as specified in this
                policy or as we disclose to you outside of this policy),
                including, to provide information and products to you, comply
                with our legal obligations, resolve disputes, and enforce our
                agreements.
              </Typography>

              <Typography variant="body1">
                Upon your registration of an account on the Site, you may elect
                to receive promotional, marketing, or other similar emails
                tailored to your interests. You may change this election at any
                time. Additionally, you may also unsubscribe from any
                promotional, marketing, or other similar emails you receive. We
                will send you confirmation emails and other Site-related
                announcements when it is necessary to do so.
              </Typography>

              <Typography variant="h5">G. Security Measures</Typography>
              <Typography variant="body1">
                When necessary, we have implemented security measures designed
                to protect against the loss, misuse, and alteration of the
                information we collect or receive from you. Nevertheless, no
                security measures are impenetrable and there are always security
                risks.
              </Typography>

              <Typography variant="body1">
                We will use the information we collect in ways that are relevant
                and compatible with the purpose for which that information was
                collected or provided to us as disclosed in this policy. We will
                use safeguards to ensure that all information collected,
                processed, and/or stored is protected from loss, destruction, or
                use in a manner inconsistent with our policies or the purpose
                for which we received it.
              </Typography>

              <Typography variant="body1">
                Our Site, as well as advertisements on our Site, may include
                links to other websites whose privacy practices may differ from
                ours. If you submit personal information to any of those
                websites, the privacy statements and practices of those websites
                govern their use of your information. Please be sure to review
                the privacy statement of any website you visit.
              </Typography>

              <Typography variant="body1">
                Other third parties or advertisers may utilize framing
                techniques to serve content to and from webpages accessible
                through our Site while preserving the look and feel of our
                website. Please note that you may be providing your personal
                information to this third-party (and not to us) in this
                situation.
              </Typography>

              <Typography variant="h5">H. Minors/Children</Typography>
              <Typography variant="body1">
                Registration on this Site by anyone under 13 years of age is
                prohibited. In accordance with the Children’s Online Privacy
                Protection Act (COPPA), we never knowingly collect or maintain
                information on our Site from those we know are under the age of
                13, and no part of our website is structured to attract anyone
                under 13. Upon notice that we have collected information from
                children under the age of 13, we will take immediate steps for
                its removal.
              </Typography>

              <Typography variant="h5">I. State Laws</Typography>
              <Typography variant="body1">
                Your personal information and its collection, use, sources, and
                sharing are described in this policy. Please see below for
                additional provisions, as required under the consumer privacy
                law of your state.
              </Typography>

              <Typography variant="h6">California Residents</Typography>
              <Typography variant="body1">
                Your rights under Cal. Civ. Code § 1798.83 (Shine the Light
                law): We only share personal information (as defined) with third
                parties for direct marketing purposes if you either specifically
                opt-in, or are offered the opportunity to opt-out and elect not
                to opt-out of such sharing at the time you provide personal
                information or when you choose to participate in a feature on
                the Site. If you do not opt-in or if you opt-out at that time,
                we will not share your personal information with that identified
                third party for direct marketing purposes.
              </Typography>

              <Typography variant="body1">
                Your rights under Cal. Bus. & Prof. Code § 22575(b) (“Do Not
                Track” Browser Settings): You are entitled to know how we
                respond to “Do Not Track” browser settings. Like many other
                websites, we do not currently alter our practices when we
                receive Do Not Track signals as there is no consensus among
                industry participants as to what “Do Not Track” means in this
                context. However, you have the option to opt-out of
                interest-based advertising. To find out more about “Do Not
                Track,” you may wish to visit www.allaboutdnt.com/.Your rights
                under the California Consumer Privacy Act of 2018 (CCPA): CCPA
                provides for certain disclosures about your rights and the
                personal information we collect, sell, and disclose for a
                business purpose. To view this information or exercise your
                rights under CCPA, visit CCPA Privacy Policy
                (https://therickle.com/ccpa) for CA residents.
              </Typography>

              <Typography variant="h6">Nevada Residents</Typography>
              <Typography variant="body1">
                If you are a resident of Nevada, you may apply limits to the
                sale of certain personal information to third parties for resale
                or licensing purposes, subject to applicable law. We do not sell
                your personal information for such use. You are entitled to
                register your preference for limits on such sales in the future
                by sending an email to us with the subject line, “Nevada Do Not
                Sell Request” along with your first and last name, zip code, and
                whether you are a former or current accountholder or user. Your
                email address must match the email address on your account to
                process this request.
              </Typography>

              <Typography variant="h5">
                J. Changes to our Privacy Policy
              </Typography>
              <Typography variant="body1">
                Our privacy policies will be periodically reviewed and evaluated
                in connection with new products, new services, new technologies,
                changes in law and your needs. We may make changes or
                modifications to our policy as a result thereof. Our policy will
                be updated from time to time. If we make any material changes to
                this policy, we will post the updated terms of the policy on the
                Site, and provide you notice of such changes, which may include
                notice by email through a message sent to the email address you
                use to access the Site or to register as a user on this Site or
                posting a message on the Service.
              </Typography>

              <Typography variant="body1">
                Modifications to our policy will be effective within thirty (30)
                calendar days following our email notice to you. You are
                responsible for updating your account information to provide us
                with your most current email address as the email notice
                constitutes effective notice of said changes.
              </Typography>

              <Typography variant="h5">K. Miscellaneous</Typography>
              <Typography variant="body1">
                From time to time, we shall conduct compliance audits of our
                relevant privacy practices to verify adherence to this policy
                and that said policy is current and accurate. Any employee that
                we determine is in violation of this policy may be subject to
                disciplinary action.
              </Typography>

              <Typography variant="body1">
                Any complaints or disputes as to collection or use of
                information under this policy shall be completely investigated
                to seek a resolution or cooperate with dispute resolution
                procedures, if applicable to the dispute.
              </Typography>

              <Typography variant="h5">L. Contact Us</Typography>
              <Typography variant="body1">
                If you have any questions about this privacy policy, our privacy
                practices or how we collect or process your personal data,
                please contact us at: support@rickletoken.com or write to us at:
                <address>
                  Attn: User Support 429 1st Street Casa Arkansas 72025
                </address>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <RickleFooter />
    </React.Fragment>
  );
}
